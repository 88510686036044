body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;

  scroll-behavior: smooth;
}

/* For Webkit (Chrome, Safari, etc.) */
::-webkit-scrollbar {
  width: 8px; /* Adjust width for vertical scrollbars */
  height: 8px; /* Adjust height for horizontal scrollbars */
}

::-webkit-scrollbar-track {
  background: #f0f0f0; /* Color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: #bdbdbd; /* Color of the scroll thumb */
  border-radius: 4px; /* Roundness of the scroll thumb */
  border: 2px solid #f0f0f0; /* Creates padding around the scroll thumb */
}

/* For IE and Edge */
* {
  scrollbar-width: thin; /* "auto" or "thin" */
  scrollbar-color: #bdbdbd #f0f0f0; /* thumb and track color */
}

@media screen and (min-width: 992px) {
  .trapezoid::before {
    content: "";
    position: absolute;
    min-width: 50%;
    min-height: 50%;
    top: 15%;
    bottom: 15%;
    left: 45%;
    right: 5%;
    background: inherit;
    z-index: 2;
    border-radius: 30px;
    transform: skew(3deg, 0deg);
  }
}

.playButton {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: relative;
}

.playButton::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  transition: transform 0.3s ease-in-out;
}

.playButton:hover::before {
  transform: translate(-50%, -50%) scale(3);
  /* Adjust scale based on the desired final size */
}
